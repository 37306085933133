<script setup lang="ts">
import type { IconName } from '#build/icons'
import LeasePageEmpty from '@register/components/LeasePageEmpty.vue'

interface Props {
  name?: string
  button?: string
  description?: string
  isLoading: boolean
  isEmpty?: boolean
  error?: Error | null
  empty?: {
    title: string
    description?: string
    button?: string
    to?: string
    icon?: IconName
  }
  to?: string
}

const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  isEmpty: false,
  error: undefined,
  name: undefined,
  button: undefined,
  description: undefined,
  to: undefined,
  empty: undefined,
})
const { documentName } = useCurrentLease()

// Events
const emit = defineEmits<{
  click: []
  error: []
  empty: []
}>()

const name = computed(() => props.name ?? documentName.value)
const error = computed(() => {
  if (props.error) return createError(props.error)
  return null
})
</script>

<template>
  <div
    class="flex w-full flex-col overflow-auto scroll-smooth p-4 print:bg-white print:text-black"
  >
    <!-- Header title -->
    <div class="flex w-full flex-col lg:flex-row lg:justify-between">
      <span>
        <h2>
          {{ name }}
          <span v-if="props.name" class="text-lg font-light text-gray-400">
            | {{ documentName }}
          </span>
        </h2>
        <p v-if="description" class="mr-4 text-sm text-gray-400">
          {{ description }}
        </p>
      </span>
      <div v-if="button" class="mt-4 flex items-center lg:mt-0">
        <Button
          v-if="to"
          color="secondary"
          size="sm"
          class="whitespace-nowrap"
          :to="to"
        >
          {{ button }}
        </Button>
        <Button
          v-else
          color="secondary"
          size="sm"
          class="whitespace-nowrap"
          @click="emit('click')"
        >
          {{ button }}
        </Button>
      </div>
    </div>

    <!-- Error -->
    <div v-if="error">
      <slot name="error">
        <div class="mt-20 flex w-full justify-center">
          <PageError :error="error" />
        </div>
      </slot>
    </div>

    <div v-else-if="isLoading" class="mt-4">
      <slot name="loading"> isLoading... </slot>
    </div>

    <LeasePageEmpty
      v-else-if="isEmpty && empty"
      v-bind="empty"
      @click="$emit('empty')"
    >
      <slot v-if="$slots.empty" name="empty" />
    </LeasePageEmpty>

    <section v-else class="mt-4 w-full">
      <slot />
    </section>
  </div>
</template>
